










































































import PasswordInput from '@/components/inputs/PasswordInput.vue'
import { Vue, Component } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { CHANGE_PASSWORD, SIGN_IN } from '@/store/auth'
import { CONTACT_EMAIL } from '@/modules/config'

@Component({
  head: {
    title() {
      return {
        inner: 'Reset password',
      }
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    PasswordInput,
  },
})
export default class PasswordReset extends Vue {
  CONTACT_EMAIL = CONTACT_EMAIL
  loading = false
  password = ''
  errors: string[] = []
  linkExpired = false

  async beforeMount() {
    // expect query params: username, email, code
    const requiredQueryParams = ['u', 'c']
    for (const param of requiredQueryParams) {
      if (
        !this.$route.query[param] ||
        Array.isArray(this.$route.query[param])
      ) {
        this.errors = ['Missing required information']
        break
      }
    }
  }

  async setPassword() {
    this.loading = true
    this.errors = []
    try {
      await this.$store.dispatch(CHANGE_PASSWORD, {
        username: this.$route.query.u,
        code: this.$route.query.c,
        newPassword: this.password,
      })
      await this.$store.dispatch(SIGN_IN, {
        username: this.$route.query.u,
        password: this.password,
      })
      // TODO: other routing for buyers
      this.$router.replace({
        path: '/releases',
        query: {
          reset: '1',
        },
      })
    } catch (err) {
      if (err.code === 'ExpiredCodeException') {
        this.linkExpired = true
      } else this.errors = [err.message]
    }

    this.loading = false
  }

  async resend() {
    this.$router.push({
      path: '/forgot-password',
      query: {
        email: this.$route.query.e,
      },
    })
  }
}
